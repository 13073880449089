import { ParameterOwner } from '../ParameterOwner.js'

/**
 * The BaseTool provides an interface you can implement to define your own mouse, keyboard, and touch event handlers.
 *
 * A tool is a class that is delegated the handling of all user interaction events. The built-in {CameraManipulator}
 * class provides a default tool for moving the viewport camera.
 *
 * You can define your own custom tool, and replace the default CameraManipulator like so.
 * ```javascript
 *   const customManipulator = new MyCustomCameraManipulator()
 *   renderer.getViewport().setManipulator(customManipulator)
 * ```
 *
 * Each tool should provide handlers for the main types of events it will be responsible for handling.
 * These include mouse, keyboard, touch and VR events. Once a tool is installed on the viewport, the viewport
 * will start invoking the handler methods defined in the interface.
 * If the Tool handles an event, it should call stopPropagation on the event so the engine knows to stop the
 * propagation of the event to other classes.
 *
 * > Note: VR Events are ann emulation of mouse events, generated by the engine, allowing custom tools to be used to provide various VR interactions.
 *
 * **Events**
 * * **installChanged:** Triggered when the tool is installed or uninstalled.
 * * **activatedChanged:** Triggered when a tool is activated or deactivated.
 *
 * @extends ParameterOwner
 */
class BaseTool extends ParameterOwner {
  /**
   * Creates an instance of BaseTool.
   */
  constructor() {
    super()
    this.__activated = false
  }

  /**
   * Enables tools usage. This method is called by either the Viewport when a tool is removed, or the ToolManage if it is installed.
   */
  activateTool() {
    if (this.__activated) throw new Error('Tool already activate')
    this.__activated = true
    this.emit('activatedChanged', { activated: this.__activated })
  }

  /**
   * Disables tool usage. This method is called by either the Viewport when a tool is removed, or the ToolManage if it is installed.
   */
  deactivateTool() {
    this.__activated = false
    this.emit('activatedChanged', { activated: this.__activated })
  }

  // ///////////////////////////////////
  // Pointer events

  /**
   * Event fired when either the mouse button is pressed, or a touch start event occurs.
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerDown(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when either the mouse cursor is moved, or a touch point moves.
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerMove(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when either the mouse button is released, or a touch end event occurs.
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerUp(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a pointing device button is double clicked.
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerDoublePress(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a mouse pointer enters the viewport
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerEnter(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a mouse pointer leaves the viewport
   *
   * @param {MouseEvent} event - The event param.
   */
  onPointerLeave(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when the user rotates the pointing device wheel.
   *
   * @param {MouseEvent} event - The event param.
   */
  onWheel(event) {
    // console.warn('Implement me')
  }

  // ///////////////////////////////////
  // Keyboard events

  /**
   * Event fired when the user presses down a key on the keyboard.
   *
   * @param {KeyboardEvent} event - The event param.
   */
  onKeyDown(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when the user releases a key on the keyboard.
   *
   * @param {KeyboardEvent} event - The event param.
   */
  onKeyUp(event) {
    // console.warn('Implement me')
  }

  // ///////////////////////////////////
  // Touch events

  /**
   * Event fired when one or more touch points are placed on the touch surface over a tool.
   *
   * @param {TouchEvent} event - The event param.
   */
  onTouchStart(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when the one or more touch points are moved along the touch surface over a tool.
   *
   * @param {TouchEvent} event - The event param.
   */
  onTouchMove(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when one or more touch points are removed from the touch surface over a tool.
   *
   * @param {TouchEvent} event - The event param.
   */
  onTouchEnd(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when one or more touch points have been disrupted in an implementation-specific manner.
   *
   * @param {TouchEvent} event - The event param.
   */
  onTouchCancel(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when two continuos touch point are placed on the touch surface over a tool.
   *
   * @param {TouchEvent} event - The event param.
   */
  onDoubleTap(event) {
    // console.warn('Implement me')
  }

  // ///////////////////////////////////
  // VRController events

  /**
   * Event fired when a VR controller button is pressed over a tool.
   *
   * @param {object} event - The event param.
   */
  onVRControllerButtonDown(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a VR controller button is released over a tool.
   *
   * @param {object} event - The event param.
   */
  onVRControllerButtonUp(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a VR controller button is pressed twice over a tool.
   *
   * @param {object} event - The event param.
   */
  onVRControllerDoubleClicked(event) {
    // console.warn('Implement me')
  }

  /**
   * Event fired when a VR controller...
   *
   * @param {object} event - The event param.
   */
  onVRPoseChanged(event) {
    // console.warn('Implement me')
  }
}

export default BaseTool
export { BaseTool }
